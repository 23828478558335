.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: black !important;

  background: linear-gradient(90deg, #c7c5f4, #776bcc);
}
.custom-tabs .nav-link {
  color: black;
}
.forgetpassword {
  color: #776bcc;
}
.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

@media (min-width: 576px) {
  .login-form {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .login-form {
    width: 30%;
  }
}

h1 {
  margin-bottom: 2rem;
}

label {
  margin-bottom: 0.5rem;
}

.inputx {
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  width: 100%;
}

buttonx {
  background-color: #7a64bc;
  color: #fff;
  padding: 0.5rem;
  border: 0px;
}
.fancy-button {
  background-color: #7a64bc;
  color: #fff;
  border: none;
  border-radius: 7px;
  font-size: 1.2rem;
  padding: 0.8rem 1.5rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
}

.fancy-button:hover {
  background-color: #5a4299;
  transform: translateY(-3px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.fancy-button:focus {
  outline: none;
}

.fancy-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.dashboard {
  // height: 100%;

  padding-top: 2%;
  display: flex;
  .sancbutton {
    padding: 0.32%;
    border: 0.31px solid rgba(0, 0, 0, 0.018);
    border-radius: 5px;
    text-align: left;
    white-space: nowrap;
    background-color: #4f0080;
    color: white;
    font-weight: 600;
  }
  .sancbutton:hover {
    color: #4f0080;
    background-color: white;
    border: 1px solid #4f0080;
  }
  .sidebar {
    background-color: rgb(79, 0, 128);
    width: 20%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: aliceblue;
    img {
      margin: 18%;
    }

    .list {
      margin: 0%;
      font-size: 1.22rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .feedback {
        margin-top: 40%;
      }
    }
    .selectdashboard {
      .dashboardbutton {
        background-color: rgb(54, 0, 88);
        color: white;
        border-left: 5px solid white;
        border-radius: 2px;
      }
    }
    .selectcustomers {
      .customersbutton {
        background-color: rgb(54, 0, 88);
        color: white;
        border-left: 5px solid white;
        border-radius: 2px;
      }
    }
    .selectanalytics {
      .analyticsbutton {
        background-color: rgb(54, 0, 88);
        color: white;
        border-left: 5px solid white;
        border-radius: 2px;
      }
    }
    .selecthelp {
      .helpbutton {
        background-color: rgb(54, 0, 88);
        color: white;
        border-left: 5px solid white;
        border-radius: 2px;
      }
    }
  }

  .dashboardcontent {
    // width: 80%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    .dashboardinner {
      // background-color: red;
      flex-wrap: wrap;
      border-radius: 5px;
      border: 2px solid #360058;
      border: 1px solid rgba(0, 0, 0, 0.085);
      display: flex;
      border: 0px solid white;
      justify-content: flex-start;
      align-items: center;
      align-content: flex-start;
      @media (max-width: 767px) {
        .grid {
          flex-direction: column;
        }
      }
      .Dashboardcard {
        display: flex;
        height: 8rem;
        padding: 2%;
        align-items: center;
        overflow: hidden;
        margin-bottom: 50px;
        margin: 2%;
        width: 15%;
        // margin-left: 5%;
        // margin-right: 5%;
        cursor: pointer;
        border-radius: 0.21rem;
        // border: 1px solid rgba(0, 0, 0, 0.094);
        // padding: 2%;
        .left {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.21rem;
          width: 40%;
          background-color: #9fc4f8;
          background-color: rgb(255, 255, 255);
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          text-align: center;
          align-items: center;
          width: 100%;
          background-color: rgba(255, 255, 255, 0);
          align-items: center;
          h5,
          h2 {
            font-size: 1rem;
          }
        }
      }

      .Dashboardcard2 {
        display: flex;
        // height: 11rem;
        // width: 18rem;
        // box-shadow: 0px 10px 35px 16px rgba(0, 0, 0, 0.1);
        border-radius: 0.51rem;
        overflow: hidden;
        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          padding: 12%;
          width: 100%;
          background-color: whitesmoke;
        }
      }
    }
    .titlex {
      font-size: 1rem;
      font-weight: 700;
    }
    .date {
      font-weight: 700;
      font-size: 0.91rem;
      color: gray;
    }
    .bottomtext {
      padding-top: 1.52%;
    }

    .tablecontainer {
      display: flex;
      border-radius: 10px;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      .pagination {
        display: flex;
        justify-content: space-evenly;
        width: 70%;
        align-self: center;
        margin-top: 4;
      }
      .dropdowns {
        display: flex;
        flex-direction: row;
        width: 20vw;
        justify-content: space-between;
        h3 {
          width: 100%;
          font-size: 1rem;
        }
      }
      .approvebuttonx {
        color: white;
        background-color: #764abc;
        border: 0;
        margin: 2%;
        width: 110%;
        text-align: center;
      }
      .approvebuttonx:hover {
        background-color: white;
        color: #764abc;
        border: 1px solid #764abc;
      }

      .tablenavbar {
        display: flex;
        position: relative;
        justify-content: space-between;
        flex-direction: row;
        @media (max-width: 755px) {
          flex-direction: column;
        }
        .navbarButtonWrap {
          display: flex;
          justify-content: space-between;
          @media (max-width: 755px) {
            flex-direction: column;
          }
        }
      }

      .css-1ex1afd-MuiTableCell-root {
        text-align: center;
        padding: 0px;
      }
      .css-11mde6h-MuiPaper-root {
        border-radius: 20px;
      }
      .tss-178gktx-MUIDataTableHeadCell-contentWrapper {
        justify-content: center;
      }
      table {
        border-collapse: collapse;
        text-align: center;
        font-size: 1rem;
        font-family: "Times New Roman", Times, serif;

        .str:hover {
          cursor: pointer;
        }
        .extradetails {
          color: black;
          width: 100;
          padding-top: 0%;
          column-span: all;
          // border-left: 1px solid #4f0080;
          background-color: #eeebff;

          // display: none;
        }
        // .selecttable {
        //   padding: 10%;
        //   border: 1px solid rgba(0, 0, 0, 0.066);
        //   border-radius: 5px;
        //   text-align: left;
        //   white-space: nowrap;
        //   font-weight: 600;
        // }
        // .selecttable:hover {
        //   background-color: #4f0080;
        //   color: white;
        // }
        .paidContainer {
          border-radius: 10px;
          border: none;
          width: 80px !important;
        }
        .statusContainer {
          border-radius: 10px;
          border: none;
          width: fit-content;
        }
        .ujwala {
          color: #64a473;
          font-weight: 600;
        }
        .pending {
          color: black;
          background-color: #ffe8aa;
        }
        .approved {
          color: black;
          background-color: #aaffbe;
        }
        .declined {
          color: white;
          background-color: #fa6666;
        }
        .ujjwalaNo {
          color: rgb(3, 201, 3);
          text-align: center;
          width: 120%;
        }
        .ujjwalaYes {
          text-align: center;
          color: rgb(179, 0, 0);
          width: 120%;
        }
        .pending,
        .approved,
        .declined {
          width: fit-content;
          padding: 3%;
          justify-self: center;
        }
      }
    }
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgb(204, 204, 204);
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(136, 105, 174);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgb(74, 27, 131);
    }
    ::-webkit-scrollbar-button:single-button:vertical:decrement {
      height: 16px;
      width: 16px;
      background-position: center 4px;
    }
  }
}

.tdx {
  color: rebeccapurple;
  margin: 0%;
}
.my-button {
  cursor: pointer;
}

.my-button:hover {
  cursor: pointer;
}
// Define a custom theme for the react-calendar component

// #573a87: ;

// Style the entire calendar
.react-calendar {
  font-family: Arial, sans-serif; // Font family
  color: rgb(158, 158, 158) !important; // Text color
}

// Style the calendar tile (the top header)
.react-calendar__tile {
  background-color: darken(#573a87, 10%) !important; // Header background color
  color: white !important; // Text color
}

// Style the navigation buttons
.react-calendar__navigation {
  button {
    background-color: darken(
      #573a87,
      10%
    ) !important; // Navigation button background color
    color: #fff; // Navigation button text color
  }
}

// Style the day labels (e.g., Mon, Tue, Wed)
.react-calendar__month-view__weekdays {
  background-color: darken(
    #573a87,
    10%
  ) !important; // Day labels background color
  color: #fff !important; // Day labels text color
}

// Style individual day cells
.react-calendar__month-view__days__day {
  &:hover {
    background-color: lighten(
      #573a87,
      10%
    ) !important; // Hove#573a87 day background color
  }
}

// Style the selected date
.react-calendar__tile--active {
  background-color: lighten(
    #573a87,
    20%
  ) !important; // Selected date background color
}

// Style today's date
.react-calendar__tile--now {
  background-color: #573a87 !important;
}

// Style the weekend days (e.g., Sat, Sun)
.react-calendar__month-view__days__day--weekend {
  color: #ff6347 !important; // Weekend day text color
}
.redcolorbg {
  background-color: #ff6347b5 !important;
}
.greencolorbg {
  background-color: #5bc68b !important;
}
td {
  background-color: transparent !important;
}
/* Legend.css */
.legend {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-weight: bold;
}

.legend-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%; /* Create a circular shape */
  margin-right: 10px;
}

.paid {
  background-color: #5bc68b; /* Green */
}

.not-paid {
  background-color: #ff6347b5; /* Red */
}

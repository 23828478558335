.upload-container {
  text-align: center;
  border-radius: 20px;
  padding: 10px 20px;
  height: 40px;
}

.upload-section {
  margin: 20px 0;
}

.head {
  padding: 10px 20px;
  margin: 20px 0;
}

.upload-button {
  background-color: #776bcc;
  border-radius: 20px;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.download-button {
  background-color: #776bcc;
  border-radius: 3px;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px ;
}
.showMore-button {
  background-color: #776bcc;
  margin-top: 10px;
  border-radius: 20px;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.success-message {
  color: green;
  font-weight: bold;
  margin-top: 10px;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}
.error2-message {
  color: #fbbc04;
  font-weight: bold;
  margin-top: 10px;
}
.validation-errors {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.validation-errors ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.validation-errors li {
  margin-top: 5px;
}

.upload-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #776bcc;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #776bcc;
}

.error-table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.error-table th,
.error-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.error-table th {
  background-color: #ae1521;
  color: #fff;
}

.error-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.pagination {
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 16px;
  background-color: #776bcc;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.pagination button:hover {
  background-color: #776bcc;
}

.pagination span {
  font-weight: bold;
  margin: 0 10px;
  font-size: 16px;
}
.error-message,
.success-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 8px 16px;
  margin-bottom: 10px;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}
/* CSS for the error2 class and theme color */
.error2-table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.error2-table th,
.error2-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.error2-table th {
  background-color: #fbbc04; /* Theme color */
  color: #fff;
}

.error2-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.error2-message {
  background-color: #fbbd04a4; /* Change the background color */
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 8px 16px;
  margin-bottom: 0px;
}

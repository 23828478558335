.distributor-container {
  display: flex;
  flex-direction: column;
  padding: 2;
}
.distributor-container2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.card {
  width: 400px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin: 20px;
  padding: 20px;
}

.info {
  text-align: center;
}

.info h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.info p {
  font-size: 18px;
  color: #666;
}

.graph-container {
  margin-top: 20px;
}
.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
}
